import { useState, useEffect } from "react";

import { API_SERVER_URL } from 'gatsby-env-variables-fork';
import apiUrls from "../apiUrls";

const url = typeof window !== 'undefined' ? `${API_SERVER_URL}${apiUrls.getAppSettings}` : '';

interface IBackendApplicationSettings {
  isBillingEnabled: boolean;
}

export const useBackendSettings = () => {
  const [settings, setSettings] = useState<IBackendApplicationSettings | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(url, {
          credentials: "include",
          referrer: "/",
          cache: "no-cache",
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        const data = await response.json();
        setSettings(data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return settings;
};
